import "./App.css";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import Services from "./components/Services";
import Orders from "./components/Orders";
import Apis from "./components/Apis";
import Login from "./components/Login/Login";
import SocialNetworks from "./components/SocialNetworks";
import Posts from "./components/Posts";
import Order from "./components/Order";
import WosApi from "./models/WosApi";
import {de} from "suneditor/src/lang";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const setToken = (userToken: any) => {
      WosApi.setToken(userToken)
      navigate("/");
  };

  const getToken = () => {
      return WosApi.getToken();
  };

  const handleExit = () => {
    WosApi.setToken(null);
    navigate("/")
  };

  const token = getToken();

  if (token == null) {
    return <Login setToken={setToken} />;
  }

  return (
    <div className="layout-container">
      <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-4">
          <li className={"menu-item " + (location.pathname === "/" ? "active" : "")}>
            <Link to={"/"}>
              <div className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Услуги</div>
              </div>
            </Link>
          </li>
          <li className={"menu-item " + (location.pathname === "/orders" ? "active" : "")}>
            <Link to={"/orders"}>
              <div className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Заказы</div>
              </div>
            </Link>
          </li>
          <li className={"menu-item " + (location.pathname === "/apis" ? "active" : "")}>
            <Link to={"/apis"}>
              <div className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Панели</div>
              </div>
            </Link>
          </li>
          <li className={"menu-item " + (location.pathname === "/social-networks" ? "active" : "")}>
            <Link to={"/social-networks"}>
              <div className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Соцсети</div>
              </div>
            </Link>
          </li>
          <li className={"menu-item " + (location.pathname === "/posts" ? "active" : "")}>
            <Link to={"/posts"}>
              <div className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Статьи</div>
              </div>
            </Link>
          </li>
          <li className="mt-4 menu-item" onClick={handleExit} >
              <Link to={"/"}>
                  <div className="menu-link">
                    <i className="menu-icon tf-icons bx bx-log-out-circle"></i>
                    <div>Выход</div>
                  </div>
              </Link>
          </li>
        </ul>
      </aside>
      <div className="layout-page">
        <Routes>
          <Route path="/">
            <Route index element={<Services />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order">
              <Route path=":billId" element={<Order />} />
            </Route>
            <Route path="apis" element={<Apis />} />
            <Route path="social-networks" element={<SocialNetworks />} />
            <Route path="posts" element={<Posts />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
