import { useState } from "react"
import { Button } from "react-bootstrap"
import WosApi from "../models/WosApi"

function AddApiForm(props: { onAdd: () => void }) {
    const [baseUrl, setBaseUrl] = useState<string>('')
    const [key, setKey] = useState<string>('')

    const addApi = async (baseUrl: string, key: string) => {
      await WosApi.addApi(baseUrl, key)
      props.onAdd()
    }

    return (
        <tr>
            <td></td>
            {/* <td></td> */}
            {/* <td></td> */}
            <td><input type="text" onChange={(e) => setBaseUrl(e.target.value)} /></td>
            <td><input type="text" onChange={(e) => setKey(e.target.value)} /></td>
            <td></td>
            <td>
                <Button variant="success" onClick={() => addApi(baseUrl, key)}><i className="bx bx-plus-circle"></i></Button>
            </td>
        </tr>
    ) 
}

export default AddApiForm
