import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WosApi, { Order as OrderType } from "../models/WosApi";

export default function Order() {
  let { billId } = useParams()
  const [order, setOrder] = useState<OrderType<number>>()

  useEffect(() => {
    loadOrder()
  }, [])

  const loadOrder = async () => {
    let orders = await WosApi.getOrders({ bill_id: billId })
    setOrder(orders.data[0])
  }

  const updateBillInfo = async (billId: string) => {
    try {
      await WosApi.updateBill(billId)
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      loadOrder() 
    }
  }

  const forceBuy = async (billId: string) => {
    // try {
    //   await WosApi.forceBuy(billId)
    // } catch (error) {
    //   if (error instanceof Error) {
    //     alert(error.message)
    //   }
    // } finally {
    //   loadOrder()
    // }
  }

  const updateWosApiOrderStatus = async (billId: string) => {
    try {
      await WosApi.updateWosApiOrderStatus(billId)
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      loadOrder()
    }
  }

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Dashboard /</span> Заказ</h4>
        <div className="card">
          <h5 className="card-header">Заказ {billId}</h5>
          {order && 
          <div className="table-responsive text-nowrap">
            <table className="table">
                <thead>
                  <tr>
                    <th>key</th>
                    <th>value</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  <tr>
                    <td>id</td>
                    <td>{order.id}</td>
                  </tr>
                  <tr>
                    <td>bill_id</td>
                    <td>{order.bill_id}</td>
                  </tr>
                  <tr>
                    <td>created_at</td>
                    <td>{order.created_at}</td>
                  </tr>
                  <tr>
                    <td>updated_at</td>
                    <td>{order.updated_at}</td>
                  </tr>
                  <tr>
                    <td>user_id</td>
                    <td>{order.user_id}</td>
                  </tr>
                  <tr>
                    <td>panel_id</td>
                    <td>{order.panel_id}</td>
                  </tr>
                  <tr>
                    <td>service</td>
                    <td><pre>{JSON.stringify(JSON.parse(order.service ?? '{}'), null, 2)}</pre></td>
                  </tr>
                  <tr>
                    <td>params</td>
                    <td><pre>{JSON.stringify(JSON.parse(order.params ?? '{}'), null, 2)}</pre></td>
                  </tr>
                  <tr>
                    <td>price</td>
                    <td>{order.price}</td>
                  </tr>
                  <tr>
                    <td>status</td>
                    <td>{order.status === "" ? "NOT PAID" : order.status}</td>
                  </tr>
                  <tr>
                    <td>bill_info</td>
                    <td>
                      <pre>{JSON.stringify(JSON.parse(order.bill_info === "" || order.bill_info === null  ? '{}' : order.bill_info), null, 2)}</pre>
                      {/*<button type="button" className="btn btn-primary" onClick={() => updateBillInfo(order.bill_id)}>Перезапустить процесс покупки<i className="bx bx-reset"></i></button>*/}
                    </td>
                  </tr>
                  <tr>
                    <td>wos_api_get_order_id_response</td>
                    <td>
                      <pre>{JSON.stringify(JSON.parse(order.panel_get_order_id_response ?? '{}'), null, 2)}</pre>
                      <button type="button" className="btn btn-danger" onClick={() => forceBuy(order.bill_id)}>Создать заказ вручную (НЕ РАБОТАЕТ)<i className="bx bx-error"></i></button>
                    </td>
                  </tr>
                  <tr>
                    <td>wos_api_order_status_response</td>
                    <td>
                      <pre>{JSON.stringify(JSON.parse(order.panel_order_status_response ?? '{}'), null, 2)}</pre>
                      <button type="button" className="btn btn-primary" onClick={() => updateWosApiOrderStatus(order.bill_id)}>Update wos api order status <i className="bx bx-reset"></i></button>
                    </td>
                  </tr>
                </tbody>
            </table>
          </div>
          }
        </div>
      </div>
    </div>
  );
}
