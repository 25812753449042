import { useEffect, useState } from "react";
import { Button, Container, Form, Image, Modal } from "react-bootstrap";
import WosApi, { PaginatedData, Post } from "../models/WosApi";
import FileUtility from "../utils/FileUtility";
import Pagination from "./Pagination";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

function Posts() {

    const [postsPage, setPostsPage] = useState<PaginatedData<Post<number>>>()
    const [newPost, setNewPost] = useState<Post<undefined> | Post<number>>()
    const [modalVisibility, setModalVisibility] = useState<boolean>()
    const [imageFile, setImageFile] = useState<File>()
    const [imageSrc, setImageSrc] = useState<string>()

    useEffect(() => {
      getPosts()
    }, [])

    const getPosts = async (pageNum: number = 1) => {
      setPostsPage(await WosApi.getPosts(pageNum))
    }
    const createPost = async (post: Post<undefined>) => {
      await WosApi.addPost(post, imageFile)
      getPosts()
    }
    const updatePost = async (post: Post<number>) => {
      await WosApi.updatePost(post, imageFile)
      getPosts()
    }
    const deletePost = async (id: number) => {
      await WosApi.deletePost(id)
      getPosts()
    }
    const openModal = (post?: Post<number>) => {
      setImageFile(undefined)
      setImageSrc((post) ? post.image : undefined)
      setNewPost(post ? post : {
        id: undefined,
        created_at: null,
        updated_at: null,
        title: '',
        content: '',
        image: "",
      })
      setModalVisibility(true)
    }
    const closeModal = () => {
      setModalVisibility(false)
    }
    const applyPost = async () => {
      if (newPost === undefined) return
      if (newPost.id === undefined) {
        await createPost(newPost)
      } else {
        await updatePost(newPost)
      }
    }
    const attachImageFile = async () => {
      let file: File | undefined = FileUtility.selector2File('#formFile')
      if (file === undefined) {
        setImageFile(undefined)
        return
      }
      setImageFile(file)
      let fileSrc = await FileUtility.file2FileSrc(file)
      setImageSrc(fileSrc)
    }

    return (
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Dashboard /</span> Статьи</h4>
          <div className="card">
            <h5 className="card-header">Статьи</h5>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>title</th>
                    {/* <th>content</th> */}
                    <th>created_at</th>
                    <th>updated_at</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {(postsPage && postsPage.current_page === 1) &&
                  <tr>
                    <td></td>
                    <td></td>
                    {/* <td></td> */}
                    <td></td>
                    <td></td>
                    <td>
                      <button type="button" className="btn btn-success" onClick={() => openModal()}><i className='bx bx-plus-circle'></i></button>
                    </td>
                  </tr>
                  }
                  {postsPage && postsPage.data.map(post => {
                      return <tr key={post.id}>
                        <td>{post.id}</td>
                        <td>{post.title}</td>
                        {/* <td>{post.content}</td> */}
                        <td>{post.created_at}</td>
                        <td>{post.updated_at}</td>
                        <td>
                          <button type="button" className="btn btn-warning" onClick={() => openModal(post)}><i className='bx bx-edit-alt'></i></button>
                          <button type="button" className="btn btn-danger ms-1" onClick={() => deletePost(post.id)}><i className='bx bx-trash'></i></button>
                        </td>
                      </tr>
                  })}
                </tbody>
              </table>
            </div>
            {postsPage && <Pagination currentPage={postsPage.current_page} lastPage={postsPage.last_page} onSetPage={(pageNum) => getPosts(pageNum)} />}
            <Modal show={modalVisibility} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>Статья</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput0">
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Заголовок"
                      value={newPost ? newPost.title : undefined}
                      onChange={(e) => {
                        let _newPost = JSON.parse(JSON.stringify(newPost))
                        _newPost.title = e.target.value
                        setNewPost(_newPost)
                      }} />
                  </Form.Group>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Изображение</Form.Label>
                      <Form.Control
                          type="text"
                          placeholder="Ссылка"
                          value={newPost ? newPost.image : undefined}
                          onChange={(e) => {
                              let _newPost = JSON.parse(JSON.stringify(newPost))
                              _newPost.image = e.target.value
                              setNewPost(_newPost)
                          }} />
                    {/*<Form.Control type="file" accept="image/png, image/jpeg" onChange={() => attachImageFile()} />*/}
                    {/*<Container>*/}
                    {/*  <Image className="img-fluid mt-3" src={imageSrc}/>*/}
                    {/*</Container>*/}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Описание</Form.Label>
                    <SunEditor
                      defaultValue={newPost ? newPost.content : undefined}
                      onChange={(content) => {
                        let _newPost = JSON.parse(JSON.stringify(newPost))
                        _newPost.content = content
                        setNewPost(_newPost)
                      }} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>Отмена</Button>
                <Button variant="warning" onClick={() => applyPost().finally(() => closeModal())}>Применить</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    )

}

export default Posts
