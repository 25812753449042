import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import WosApi, { PaginatedData, SocialNetwork } from "../models/WosApi";
import AddSocialNetworkForm from "./AddSocialNetworkForm";
import Pagination from "./Pagination";

function SocialNetworks() {

    const [socialNetworksPage, setSocialNetworksPage] = useState<PaginatedData<SocialNetwork<number>>>()

    useEffect(() => {
      getSocialNetworks()
    }, [])

    const getSocialNetworks = async (pageNum: number = 1) => {
      setSocialNetworksPage(await WosApi.getSocialNetworks(pageNum))
    }

    const deleteSocialNetwork = async (id: number) => {
      await WosApi.deleteSocialNetwork(id)
      getSocialNetworks()
    }

    return (
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Dashboard /</span> Соцсети</h4>
          <div className="card">
            <h5 className="card-header">Соцсети</h5>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>id</th>
                    {/* <th>created_at</th> */}
                    {/* <th>updated_at</th> */}
                    <th>name</th>
                    <th>codename</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {(socialNetworksPage && socialNetworksPage.current_page === 1) && <AddSocialNetworkForm onAdd={() => getSocialNetworks()}/>}
                  {socialNetworksPage && socialNetworksPage.data.map(api => {
                      return <tr key={api.id}>
                        <td>{api.id}</td>
                        {/* <td>{api.created_at}</td> */}
                        {/* <td>{api.updated_at}</td> */}
                        <td>{api.name}</td>
                        <td>{api.codename}</td>
                        <td>
                          <Button variant="danger" onClick={() => deleteSocialNetwork(api.id)}><i className="bx bx-trash"></i></Button>
                        </td>
                      </tr>
                  })}
                </tbody>
              </table>
            </div>
            {socialNetworksPage && <Pagination currentPage={socialNetworksPage.current_page} lastPage={socialNetworksPage.last_page} onSetPage={(pageNum) => getSocialNetworks(pageNum)} />}
          </div>
        </div>
      </div>
    )

}

export default SocialNetworks
