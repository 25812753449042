import { useState } from "react"
import { Button } from "react-bootstrap"
import WosApi from "../models/WosApi"

function AddSocialNetworkForm(props: { onAdd: () => void }) {
    const [name, setName] = useState<string>('')
    const [codename, setCodename] = useState<string>('')

    const addSocialNetwork = async (name: string, codename: string) => {
      await WosApi.addSocialNetwork(codename, name)
      props.onAdd()
    }

    return (
        <tr>
            <td></td>
            {/* <td></td> */}
            {/* <td></td> */}
            <td><input type="text" onChange={(e) => setName(e.target.value)} /></td>
            <td><input type="text" onChange={(e) => setCodename(e.target.value)} /></td>
            <td>
                <Button variant="success" onClick={() => addSocialNetwork(name, codename)}><i className="bx bx-plus-circle"></i></Button>
            </td>
        </tr>
    ) 
}

export default AddSocialNetworkForm
