import { useEffect, useState } from "react";
import WosApi, { Order as OrderType, PaginatedData } from "../models/WosApi";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";

function Orders() {

    const [ordersPage, setOrdersPage] = useState<PaginatedData<OrderType<number>>>()

    useEffect(() => {
      getOrders()
    }, [])

    const getOrders = async (pageNum: number = 1) => {
      setOrdersPage(await WosApi.getOrders({ page: pageNum }))
    }

    return (
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Dashboard /</span> Заказы</h4>
          <div className="card">
            <h5 className="card-header">Заказы</h5>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>bill_id</th>
                    <th>service category</th>
                    <th>email</th>
                    <th>price</th>
                    <th>panel status</th>
                    <th>created_at</th>
                    <th>updated_at</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {ordersPage && ordersPage.data.map((order) => {
                    return <tr key={order.bill_id}>
                      <td><Link to={"/order/" + order.bill_id}>{order.bill_id}</Link></td>
                      <td>{JSON.parse(order.service ?? '{}')?.category ?? '?'}</td>
                      <td>{JSON.parse(order.params ?? '{}')?.email ?? '?'}</td>
                      <td>{order.price}</td>
                      <td><span className="badge bg-label-primary me-1">{order.status}</span></td>
                      <td>{order.created_at}</td>
                      <td>{order.updated_at}</td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            {ordersPage && <Pagination currentPage={ordersPage.current_page} lastPage={ordersPage.last_page} onSetPage={(pageNum: number) => getOrders(pageNum)} />}
          </div>
        </div>
      </div>
    );

}

export default Orders
