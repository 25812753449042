import { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import WosApi, { Api } from "../models/WosApi"

function ApiEditor(props: { show: boolean, onHide: () => void, initialValue: Api<number> }) {
    const [baseUrl, setBaseUrl] = useState<string>(props.initialValue.base_url)
    const [key, setKey] = useState<string>(props.initialValue.api_key)

    const edit = async () => {
      let newValue = Object.assign({}, props.initialValue)
      newValue.base_url = baseUrl
      newValue.api_key = key
      await WosApi.updateApi(newValue)
      props.onHide()
    }

    return (
      <Modal show={props.show} onHide={() => props.onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Редактор панели</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput0">
              <Form.Label>baseUrl</Form.Label>
              <Form.Control
                type="text"
                placeholder="baseUrl"
                value={baseUrl}
                onChange={(e) => setBaseUrl(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>key</Form.Label>
              <Form.Control
                type="text"
                placeholder="key"
                value={key}
                onChange={(e) => setKey(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide()}>Отмена</Button>
          <Button variant="warning" onClick={() => edit()}>Применить</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ApiEditor
