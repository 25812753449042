import { useEffect, useState } from "react"

function Pagination(props: { currentPage: number, lastPage: number, onSetPage: (pageNum: number) => void }) {

    const [buttonNumbers, setButtonNumbers] = useState<number[]>([])
    
    useEffect(() => {
        let btnNums: number[] = []
        for (let i = (props.currentPage - 5); i < (props.currentPage + 5); i++) {
            if (i > 0 && i <= props.lastPage) {
                btnNums.push(i)
            }
        }
        setButtonNumbers(btnNums)
    }, [props.currentPage, props.lastPage])

    return (
        <nav aria-label="Page navigation example">
          <ul className="pagination pagination-lg">
            <li className={"page-item " + (props.currentPage === 1 ? 'disabled' : '' )}>
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {buttonNumbers.map((buttonNumber) => {
                return <li key={'pagination_' + buttonNumber} className={"page-item " + (buttonNumber === props.currentPage ? 'active' : '')} onClick={() => props.onSetPage(buttonNumber)}><a className="page-link" href="#">{buttonNumber}</a></li>
            })}
            <li className={"page-item " + (props.lastPage === props.currentPage ? 'disabled' : '' )}>
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
    )
 
}

export default Pagination
