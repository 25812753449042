import React, { useState } from "react";

import "./Login.css";
import WosApi from "../../models/WosApi";

async function loginUser(login: string, password: string) {
    let response = await WosApi.login(login, password)
    return response.Token;
}

const Login = (props: { setToken: (token: string) => any }) => {
  const [username, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const token = await loginUser(username, password);
    props.setToken(token);
  };

  return (
    <div className="form-signin mt-5">
      <form onSubmit={handleSubmit}>
        <h1 className="h3 mb-3 fw-normal">Вход</h1>

        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder=""
            onChange={(e) => setUserName(e.target.value)}
          />
          <label htmlFor="floatingInput">Логин</label>
        </div>
          <div className="mt-2"></div>
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder=""
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">Пароль</label>
        </div>

        <button className="w-100 btn btn-lg btn-primary" type="submit">
          Войти
        </button>
      </form>
    </div>
  );
};

export default Login;
