import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import WosApi, { Api, PaginatedData } from "../models/WosApi";
import AddApiForm from "./AddApiForm";
import ApiEditor from "./ApiEditor";
import Pagination from "./Pagination";

function Apis() {

    const [apisPage, setApisPage] = useState<PaginatedData<Api<number>>>()
    const [apiEditorInitialValue, setApiEditorInitialValue] = useState<Api<number>>()
    const [apiEditorVisibility, setApiEditorVisibility] = useState<boolean>(false)

    useEffect(() => {
      getApis()
    }, [])

    const getApis = async (pageNum: number = 1) => {
      setApisPage(await WosApi.getApis(pageNum))
    }

    const deleteApi = async (id: number) => {
      await WosApi.deleteApi(id)
      getApis()
    }

    const updateBalance = async (id: number) => {
      await WosApi.updateApiBalance(id)
      getApis()
    }
   
    const openApiEditor = (api: Api<number>) => {
      setApiEditorInitialValue(api)
      setApiEditorVisibility(true)
    }
   
    const closeApiEditor = () => {
      setApiEditorVisibility(false)
      getApis()
    }

    return (
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Dashboard /</span> Панели</h4>
          <div className="card">
            <h5 className="card-header">Панели</h5>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>id</th>
                    {/* <th>created_at</th> */}
                    {/* <th>updated_at</th> */}
                    <th>baseUrl</th>
                    <th>key</th>
                    <th>balance</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {(apisPage && apisPage.current_page === 1) && <AddApiForm onAdd={() => getApis()}/>}
                  {apisPage && apisPage.data.map((api) => {
                      return <tr key={api.id}>
                        <td>{api.id}</td>
                        {/* <td>{api.created_at}</td> */}
                        {/* <td>{api.updated_at}</td> */}
                        <td>{api.base_url}</td>
                        <td>{api.api_key}</td>
                        <td>{api.balance} <Button variant="info" onClick={() => updateBalance(api.id)}><i className="bx bx-refresh"></i></Button></td>
                        <td>
                          <Button variant="warning" onClick={() => openApiEditor(api)}><i className="bx bx-edit-alt"></i></Button>
                          <Button variant="danger" onClick={() => deleteApi(api.id)} className="ms-1"><i className="bx bx-trash"></i></Button>
                        </td>
                      </tr>
                  })}
                </tbody>
              </table>
            </div>
            {apisPage && <Pagination currentPage={apisPage.current_page} lastPage={apisPage.last_page} onSetPage={(pageNum) => getApis(pageNum)} />}
            {apiEditorInitialValue && <ApiEditor show={apiEditorVisibility} onHide={() => closeApiEditor()} initialValue={apiEditorInitialValue} />}
          </div>
        </div>
      </div>
    )

}

export default Apis
