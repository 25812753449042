import { useEffect, useState } from "react";
import WosApi, { PaginatedData, Service, SocialNetwork, WhitelistItem } from "../models/WosApi";
import Pagination from "./Pagination";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, FloatingLabel, Form } from "react-bootstrap";

function Services() {

    const [servicesPage, setServicesPage] = useState<PaginatedData<Service>>()
    const [whitelistPage, setWhitelistPage] = useState<PaginatedData<WhitelistItem<number>>>()
    const [socialNetworksPage, setSocialNetworksPage] = useState<PaginatedData<SocialNetwork<number>>>()
    const [showOnlyWhitelistServices, setShowOnlyWhitelistServices] = useState<boolean>(false)

    useEffect(() => {
      getWhitelistPage()
      getSocialNetworksPage()
    }, [])

    useEffect(() => {
      let currentPage = servicesPage && servicesPage.current_page ? servicesPage.current_page : 1
      getServices(currentPage)
    }, [whitelistPage, showOnlyWhitelistServices])

    const getServices = async (pageNum: number = 1) => {
      setServicesPage(await WosApi.getServices(showOnlyWhitelistServices, pageNum))
    }

    const getWhitelistPage = async () => {
      setWhitelistPage(await WosApi.getWhitelist())
    }

    const getSocialNetworksPage = async () => {
      setSocialNetworksPage(await WosApi.getSocialNetworks())
    }

    const isInWhitelist = (panelId: number, serviceId: number) => {
      if (whitelistPage === undefined) throw new Error('whitelist isnt loaded')
      return whitelistPage.data.some(w => w.panel_id === panelId && w.service_id === serviceId)
    }

    const addWhitelist = async () => {
      if (newWhitelistItem === undefined) throw new Error('new whitelist is undefined')
      if (newWhitelistItem.panel_id ===  null) throw new Error('panel_id is null')
      if (newWhitelistItem.service_id === null) throw new Error('service_id is null')
      let whitelistItemId = getWhitelistIdByApiAndService(newWhitelistItem.panel_id, newWhitelistItem.service_id)
      if (whitelistItemId) {
        let newWhitelistItemWithId: WhitelistItem<number> = {
          id: whitelistItemId, // 6,
          created_at: null, // "2023-01-05T23:21:20.000000Z",
          updated_at: null, // "2023-01-16T21:05:25.000000Z",
          panel_id: newWhitelistItem.panel_id, // 1,
          service_id: newWhitelistItem.service_id, // 3937,
          new_rate: newWhitelistItem.new_rate, // "100.000000000",
          content: newWhitelistItem.content, // "hello world",
          new_name: newWhitelistItem.new_name, // "",
          social_network_id: newWhitelistItem.social_network_id,
          social_network: null,
        }
        await WosApi.updateWhitelist(newWhitelistItemWithId)
      } else {
        await WosApi.addWhitelist(newWhitelistItem)
      }
      getWhitelistPage()
    }

    const getWhitelistIdByApiAndService = (apiId: number, serviceId: number) => {
      if (whitelistPage === undefined) throw new Error('whitelist is undefined')
      let whitelistItem: WhitelistItem<number> | undefined = whitelistPage.data.find(wl => wl.panel_id === apiId && wl.service_id === serviceId)
      if (whitelistItem === undefined) return undefined
      return whitelistItem.id
    }

    const deleteWhitelistItem = async (apiId: number, serviceId: number) => {
      let whitelistItemId = getWhitelistIdByApiAndService(apiId, serviceId)
      if (whitelistItemId === undefined) throw new Error('whitelist item not found')
      await WosApi.deleteWhitelist(whitelistItemId)
      getWhitelistPage()
    }

    const getSocialNetworkById = (id: number): SocialNetwork<number> => {
      if (socialNetworksPage === undefined) throw new Error('social network not loaded')
      let socialNetwork = socialNetworksPage.data.find(sw => sw.id === id)
      if (socialNetwork === undefined) throw new Error('social network not found')
     return socialNetwork 
    }

    const [modalVisibility, setModalVisibility] = useState<boolean>(false)
    const [newWhitelistItem, setNewWhitelistItem] = useState<WhitelistItem<undefined>>()

    const openModal = (service: Service) => {
      let whitelistItem: WhitelistItem<undefined> = {
        id: undefined, // 6,
        created_at: null, // "2023-01-05T23:21:20.000000Z",
        updated_at: null, // "2023-01-16T21:05:25.000000Z",
        panel_id: service.panel_id, // 1,
        service_id: parseInt(service.service), // 3937,
        new_rate: service.whitelist ? service.whitelist.new_rate : service.rate, // "100.000000000",
        content: service.whitelist ? service.whitelist.content : null, // "hello world",
        new_name: service.whitelist ? service.whitelist.new_name : service.name, // "",
        social_network_id: service.whitelist && service.whitelist.social_network_id,
        social_network: service.whitelist && service.whitelist.social_network ? service.whitelist.social_network : null,
      }
      setNewWhitelistItem(whitelistItem)
      setModalVisibility(true)
    }
    const closeModal = () => {
      setModalVisibility(false)
    }

    return (
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Dashboard /</span> Услуги</h4>
          <div className="card">
            <h5 className="card-header">Услуги</h5>
            <Container>
              <Form>
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  label="Показать только добавленные услуги"
                  onChange={e => setShowOnlyWhitelistServices(e.target.checked)}
                />
              </Form>
            </Container>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>panel_id</th>
                    <th>service</th>
                    <th>name</th>
                    <th>action</th>
                    <th>rate</th>
                    <th>min</th>
                    <th>max</th>
                    <th>category</th>
                    <th>dripfeed</th>
                    <th>refill</th>
                    <th>cancel</th>
                    <th>type</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {servicesPage && servicesPage.data && servicesPage.data.map(service => {
                      let isWhite = isInWhitelist(service.panel_id, parseInt(service.service))
                      return <tr key={service.panel_id + ' ' + service.service} className={isWhite ? "table-success" : ""}>
                        <td>{service.panel_id}</td>
                        <td>{service.service}</td>
                        <td>
                          <p className={isWhite ? 'text-decoration-line-through' : ''}>{service.name}</p>
                          {(isWhite && service.whitelist) && <p>{service.whitelist.new_name}</p>}
                        </td>
                        <td>
                          <button type="button" className={"btn btn-" + (isWhite ? "warning" : "success")} onClick={() => openModal(service)}><i className={'bx bx-' + (isWhite ? 'edit-alt' : 'plus-circle')} ></i></button>
                          {isWhite && <button type="button" className="btn btn-danger ms-1" onClick={() => deleteWhitelistItem(service.panel_id, parseInt(service.service))}><i className='bx bx-trash'></i></button>}
                        </td>
                        <td>
                          <p className={isWhite ? 'text-decoration-line-through' : ''}>{service.rate}</p>
                          {(isWhite && service.whitelist) && <p>{service.whitelist.new_rate}</p>}
                        </td>
                        <td>{service.min}</td>
                        <td>{service.max}</td>
                        <td>{service.category}</td>
                        <td>{service.dripfeed}</td>
                        <td>{service.refill}</td>
                        <td>{service.cancel}</td>
                        <td>{service.type}</td>
                      </tr>
                  })}
                </tbody>
              </table>
            </div>
            {servicesPage && <Pagination currentPage={servicesPage.current_page} lastPage={servicesPage.last_page} onSetPage={(pageNum: number) => getServices(pageNum)} />}

            <Modal show={modalVisibility} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>Добавление в белый список</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput0">
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Заголовок"
                      value={newWhitelistItem && typeof newWhitelistItem.new_name === 'string' ? newWhitelistItem.new_name : undefined}
                      onChange={(e) => {
                        let _newWhitelistItem = JSON.parse(JSON.stringify(newWhitelistItem))
                        _newWhitelistItem.new_name = e.target.value
                        setNewWhitelistItem(_newWhitelistItem)
                      }} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Рейт</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0.00"
                      value={newWhitelistItem && newWhitelistItem.new_rate ? newWhitelistItem.new_rate : undefined}
                      onChange={(e) => {
                        let _newWhitelistItem = JSON.parse(JSON.stringify(newWhitelistItem))
                        _newWhitelistItem.new_rate = parseFloat(e.target.value).toString()
                        setNewWhitelistItem(_newWhitelistItem)
                      }} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      value={newWhitelistItem && newWhitelistItem.content ? newWhitelistItem.content : undefined}
                      onChange={(e) => {
                        let _newWhitelistItem = JSON.parse(JSON.stringify(newWhitelistItem))
                        _newWhitelistItem.content = e.target.value
                        setNewWhitelistItem(_newWhitelistItem)
                      }} />
                  </Form.Group>
                  <FloatingLabel controlId="floatingSelect" label="Соцсеть">
                    <Form.Select
                      aria-label="Floating label select example"
                      value={newWhitelistItem && newWhitelistItem.social_network ? newWhitelistItem.social_network.id : undefined}
                      onChange={e => {
                        let id: number | null = parseInt(e.target.value)
                        if (isNaN(id)) id = null
                        let _newWhitelistItem = JSON.parse(JSON.stringify(newWhitelistItem))
                        _newWhitelistItem.social_network_id = id
                        if (id !== null)  _newWhitelistItem.social_network = getSocialNetworkById(id)
                        setNewWhitelistItem(_newWhitelistItem)
                      }}
                    >
                      <option>Не выбран</option>
                      {socialNetworksPage && socialNetworksPage.data.map(sn => {
                        return <option value={sn.id}>{sn.name}</option>
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>Отмена</Button>
                <Button variant="warning" onClick={() => {
                  addWhitelist()
                    .then(() => getWhitelistPage())
                    .finally(() => closeModal())
                }}>Применить</Button>
              </Modal.Footer>
            </Modal>

          </div>
        </div>
      </div>
    )

}

export default Services
